<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="professionalDataForm">
      <b-form @submit.prevent="validationForm">
        <b-card
          class="p-2"
        >
          <b-row>
            <b-col>
              <!-- Study Level -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="6"
                label="Grado de instrucción"
                label-for="studyLevel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo de documento"
                  vid="studyLevel"
                >
                  <b-form-select
                    id="studyLevel"
                    v-model="studyLevel"
                    :options="optionsStudyLevel"
                    :state="errors.length > 0 ? false:null"
                    name="studyLevel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- RUC -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="2"
                label="RUC"
                label-for="ruc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="RUC"
                  rules="required"
                  vid="ruc"
                >
                  <b-form-input
                    id="ruc"
                    v-model="ruc"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterNumber"
                    name="ruc"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- profession -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="6"
                label="Profesión/Oficio"
                label-for="profession"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Profesión"
                  rules="required"
                  vid="profession"
                >
                  <v-select
                    id="profession"
                    v-model="profession"
                    label="profesion"
                    :options="optionsProfession"
                    name="profession"
                    required
                  >
                    <span slot="no-options">
                      Lo sentimos, no hay opciones que coincidan.
                    </span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- CIIU -->
              <validation-provider
                #default="{ errors }"
                name="CIIU"
                vid="ciiu"
              >
                <b-form-group
                  label-cols-sm="3"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="6"
                  label="CIIU"
                  label-for="ciiu"
                >
                  <v-select
                    id="ciiu"
                    v-model="ciiu"
                    :options="optionsCIIU"
                    name="ciiu"
                  >
                    <span slot="no-options">
                      Lo sentimos, no hay opciones que coincidan.
                    </span>
                  </v-select>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- mainActivity -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="6"
                label="Actividad Principal"
                label-for="mainActivity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Actividad principal"
                  rules="required"
                  vid="mainActivity"
                >
                  <b-form-input
                    id="mainActivity"
                    v-model="mainActivity"
                    :state="errors.length > 0 ? false:null"
                    name="mainActivity"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Job -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="6"
                label="Ocupación"
                label-for="job"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ocupación"
                  rules="required"
                  vid="job"
                >
                  <v-select
                    id="job"
                    v-model="job"
                    label="ocupacion"
                    :options="optionsActivities"
                    name="job"
                    required
                  >
                    <span slot="no-options">
                      Lo sentimos, no hay opciones que coincidan.
                    </span>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-show="job && (job.compuestoID=='000004'|| job.compuestoID=='000003')"
        >
          <b-row>
            <b-col
              cols="6"
            >
              <!-- workplace -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Centro laboral/Razón social del negocio*"
                label-for="workplace"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Centro laboral/Razón social del negocio"
                  vid="workplace"
                >
                  <b-form-input
                    id="workplace"
                    v-model="workplace"
                    :state="errors.length > 0 ? false:null"
                    name="workplace"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <!-- wpRuc -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="RUC*"
                label-for="wpRuc"
              >
                <validation-provider
                  #default="{ errors }"
                  name="RUC"
                  vid="wpRuc"
                >
                  <b-form-input
                    id="wpRuc"
                    v-model="wpRuc"
                    :state="errors.length > 0 ? false:null"
                    name="wpRuc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
            >
              <!-- wpStartdate -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Fecha de inicio laboral/negocio*"
                label-for="wpStartdate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fecha de inicio laboral/negocio"
                  vid="wpStartdate"
                >
                  <!-- <b-form-datepicker
                    id="wpStartdate"
                    v-model="wpStartdate"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="es"
                    name="wpStartdate"
                    :state="errors.length > 0 ? false:null"
                    required
                  />
                  <b-form-input
                    v-show="false"
                    v-model="wpStartdate"
                    :state="errors.length > 0 ? false:null"
                  /> -->
                  <flat-pickr
                    id="wpStartdate"
                    v-model="wpStartdate"
                    placeholder="dd/mm/aaaa"
                    name="wpStartdate"
                    :state="errors.length > 0 ? false:null"
                    :config="{ ...configDatePickr }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <!-- wpPosition -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Cargo*"
                label-for="wpPosition"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cargo"
                  vid="wpPosition"
                >
                  <b-form-input
                    id="wpPosition"
                    v-model="wpPosition"
                    :state="errors.length > 0 ? false:null"
                    name="wpPosition"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <!-- wpPhone -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Teléfono*"
                label-for="wpPhone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Teléfono"
                  rules="max:15"
                  vid="wpPhone"
                >
                  <b-form-input
                    id="wpPhone"
                    v-model="wpPhone"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterTel"
                    name="wpPhone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
            >
              <!-- wpEconomic -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Rubro económico*"
                label-for="wpEconomic"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Rubro económico"
                  vid="wpEconomic"
                >
                  <b-form-input
                    id="wpEconomic"
                    v-model="wpEconomic"
                    :state="errors.length > 0 ? false:null"
                    name="wpEconomic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <!-- wpEmail -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Correo electrónico*"
                label-for="wpEmail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Correo electrónico"
                  rules="email"
                  vid="wpEmail"
                >
                  <b-form-input
                    id="wpEmail"
                    v-model="wpEmail"
                    :state="errors.length > 0 ? false:null"
                    name="wpEmail"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="3"
            >
              <!-- wpAddress -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Dirección*"
                label-for="wpAddress"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dirección"
                  vid="wpAddress"
                >
                  <b-form-input
                    id="wpAddress"
                    v-model="wpAddress"
                    :state="errors.length > 0 ? false:null"
                    name="wpAddress"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <!-- wpStreet -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Urbanización"
                label-for="wpStreet"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Urbanización"
                  vid="wpStreet"
                >
                  <b-form-input
                    id="wpStreet"
                    v-model="wpStreet"
                    :state="errors.length > 0 ? false:null"
                    name="wpStreet"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
            >
              <!-- wpStreetNumber -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="N°"
                label-for="wpStreetNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="N°"
                  vid="wpStreetNumber"
                >
                  <b-form-input
                    id="wpStreetNumber"
                    v-model="wpStreetNumber"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterNumber"
                    name="wpStreetNumber"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
            >
              <!-- wpBlock -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Manzana"
                label-for="wpBlock"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Manzana"
                  vid="wpBlock"
                >
                  <b-form-input
                    id="wpBlock"
                    v-model="wpBlock"
                    :state="errors.length > 0 ? false:null"
                    name="wpBlock"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
            >
              <!-- wpLot -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Lote"
                label-for="wpLot"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lote"
                  vid="wpLot"
                >
                  <b-form-input
                    id="wpLot"
                    v-model="wpLot"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterNumber"
                    name="wpLot"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
            >
              <!-- wpDepNumber -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="N° Dep"
                label-for="wpDepNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="N° Dep"
                  vid="wpDepNumber"
                >
                  <b-form-input
                    id="wpDepNumber"
                    v-model="wpDepNumber"
                    :state="errors.length > 0 ? false:null"
                    name="wpDepNumber"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
            >
              <!-- wpInside -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Interior"
                label-for="wpInside"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Interior"
                  vid="wpInside"
                >
                  <b-form-input
                    id="wpInside"
                    v-model="wpInside"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterNumber"
                    name="wpInside"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="3"
            >
              <!-- wpDistrict -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Distrito*"
                label-for="wpDistrict"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Distrito"
                  vid="wpDistrict"
                >
                  <b-form-input
                    id="wpDistrict"
                    v-model="wpDistrict"
                    :state="errors.length > 0 ? false:null"
                    name="wpDistrict"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <!-- wpProvince -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Provincia*"
                label-for="wpProvince"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Provincia"
                  vid="wpProvince"
                >
                  <b-form-input
                    id="wpProvince"
                    v-model="wpProvince"
                    :state="errors.length > 0 ? false:null"
                    name="wpProvince"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
            >
              <!-- wpDepartment -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Departamento*"
                label-for="wpDepartment"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Departamento"
                  vid="wpDepartment"
                >
                  <b-form-input
                    id="wpDepartment"
                    v-model="wpDepartment"
                    :state="errors.length > 0 ? false:null"
                    name="wpDepartment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
            >
              <!-- wpCountry -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="País*"
                label-for="wpCountry"
              >
                <validation-provider
                  #default="{ errors }"
                  name="País"
                  vid="wpCountry"
                >
                  <b-form-input
                    id="wpCountry"
                    v-model="wpCountry"
                    :state="errors.length > 0 ? false:null"
                    name="wpCountry"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
            >
              <!-- wpOld -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Antigüedad*"
                label-for="wpOld"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Antigüedad"
                  vid="wpOld"
                >
                  <b-form-input
                    id="wpOld"
                    v-model="wpOld"
                    :state="errors.length > 0 ? false:null"
                    name="wpOld"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-show="job && job.compuestoID=='000003'"
        >
          <b-row>
            <b-col>
              <!-- monthlySalary -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Ingreso Mensual"
                label-for="monthlySalary"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ingreso Mensual"
                  vid="monthlySalary"
                >
                  <b-form-radio-group
                    id="monthlySalary"
                    v-model="monthlySalary"
                    :options="optionsRevenue"
                    value-field="value"
                    text-field="text"
                    :state="errors.length > 0 ? false:null"
                    name="monthlySalary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-show="job && job.compuestoID=='000004'"
        >
          <b-row>
            <b-col>
              <!-- monthlyRevenue -->
              <b-form-group
                label-cols="12"
                content-cols="12"
                label="Venta bruta mensual"
                label-for="monthlyRevenue"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Venta bruta mensual"
                  vid="monthlyRevenue"
                >
                  <b-form-radio-group
                    id="monthlyRevenue"
                    v-model="monthlyRevenue"
                    :options="optionsRevenue"
                    value-field="value"
                    text-field="text"
                    :state="errors.length > 0 ? false:null"
                    name="monthlyRevenue"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row
          class="justify-content-center"
        >
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              :to="{name:'Contact-data'}"
              block
            >
              Volver
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar y avanzar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BButton, BRow, BCol, BFormRadioGroup, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import ciiuOptions from '@utils/ciiuOptions'
import professionsOptions from '@utils/professions'
import activitiesOptions from '@utils/activities'
import store from '@/store'

/* eslint-disable */
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
/* eslint-enable */

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormRadioGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    flatPickr,
  },
  mixins: [heightTransition],
  data() {
    return {
      email: userData.email,
      studyLevel: '',
      ruc: userData.ruc,
      profession: '',
      ciiu: '',
      mainActivity: '',
      job: '',
      workplace: '',
      wpRuc: '',
      wpStartdate: '',
      wpPosition: '',
      wpPhone: '',
      wpEconomic: '',
      wpEmail: '',
      wpAddress: '',
      wpStreet: '',
      wpStreetNumber: '',
      wpBlock: '',
      wpLot: '',
      wpDepNumber: '',
      wpInside: '',
      wpDistrict: '',
      wpProvince: '',
      wpDepartment: '',
      wpCountry: '',
      wpOld: '',
      monthlySalary: '',
      monthlyRevenue: '',
      // Options values
      optionsStudyLevel: [
        { value: '', text: 'Selecciona una opción' },
        { value: '1', text: 'Primaria' },
        { value: '2', text: 'Secundaria' },
        { value: '3', text: 'Técnico' },
        { value: '4', text: 'Universitario' },
        { value: '5', text: 'Superior Completa' },
        { value: '6', text: 'Bachiller' },
      ],
      optionsRevenue: [
        { value: '0', text: 'Hasta 1000' },
        { value: '1', text: 'De 1001 a 3000' },
        { value: '2', text: 'De 3001 a 6000' },
        { value: '3', text: 'De 6001 a 10000' },
        { value: '4', text: 'Más 10000' },
      ],
      optionsCIIU: ciiuOptions,
      optionsProfession: professionsOptions,
      optionsActivities: activitiesOptions,
      // Validation rules
      required,

      // Configs DatePickr
      configDatePickr: {
        allowInput: true,
        altInput: true,
        locale: Spanish,
        altFormat: 'd/m/Y',
      },
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.ruc = dataU.ruc
    this.studyLevel = (dataU.professionalData && dataU.professionalData.studyLevel) ? dataU.professionalData.studyLevel : ''
    this.profession = (dataU.professionalData && dataU.professionalData.profession) ? dataU.professionalData.profession : {
      compuestoID: '00099',
      profesion: 'PROFESION U OCUPACION NO ESPECIFICADA',
    }
    this.ciiu = (dataU.professionalData && dataU.professionalData.ciiu) ? dataU.professionalData.ciiu : ''
    this.mainActivity = (dataU.professionalData && dataU.professionalData.mainActivity) ? dataU.professionalData.mainActivity : ''
    this.job = (dataU.professionalData && dataU.professionalData.job) ? dataU.professionalData.job : {
      compuestoID: '000099',
      ocupacion: 'No declara',
    }
    this.workplace = (dataU.professionalData && dataU.professionalData.workplace) ? dataU.professionalData.workplace : ''
    this.wpRuc = (dataU.professionalData && dataU.professionalData.wpRuc) ? dataU.professionalData.wpRuc : ''
    this.wpStartdate = (dataU.professionalData && dataU.professionalData.wpStartdate) ? dataU.professionalData.wpStartdate : ''
    this.wpPosition = (dataU.professionalData && dataU.professionalData.wpPosition) ? dataU.professionalData.wpPosition : ''
    this.wpPhone = (dataU.professionalData && dataU.professionalData.wpPhone) ? dataU.professionalData.wpPhone : ''
    this.wpEconomic = (dataU.professionalData && dataU.professionalData.wpEconomic) ? dataU.professionalData.wpEconomic : ''
    this.wpEmail = (dataU.professionalData && dataU.professionalData.wpEmail) ? dataU.professionalData.wpEmail : ''
    this.wpAddress = (dataU.professionalData && dataU.professionalData.wpAddress) ? dataU.professionalData.wpAddress : ''
    this.wpStreet = (dataU.professionalData && dataU.professionalData.wpStreet) ? dataU.professionalData.wpStreet : ''
    this.wpStreetNumber = (dataU.professionalData && dataU.professionalData.wpStreetNumber) ? dataU.professionalData.wpStreetNumber : ''
    this.wpBlock = (dataU.professionalData && dataU.professionalData.wpBlock) ? dataU.professionalData.wpBlock : ''
    this.wpLot = (dataU.professionalData && dataU.professionalData.wpLot) ? dataU.professionalData.wpLot : ''
    this.wpDepNumber = (dataU.professionalData && dataU.professionalData.wpDepNumber) ? dataU.professionalData.wpDepNumber : ''
    this.wpInside = (dataU.professionalData && dataU.professionalData.wpInside) ? dataU.professionalData.wpInside : ''
    this.wpDistrict = (dataU.professionalData && dataU.professionalData.wpDistrict) ? dataU.professionalData.wpDistrict : ''
    this.wpProvince = (dataU.professionalData && dataU.professionalData.wpProvince) ? dataU.professionalData.wpProvince : ''
    this.wpDepartment = (dataU.professionalData && dataU.professionalData.wpDepartment) ? dataU.professionalData.wpDepartment : ''
    this.wpCountry = (dataU.professionalData && dataU.professionalData.wpCountry) ? dataU.professionalData.wpCountry : ''
    this.wpOld = (dataU.professionalData && dataU.professionalData.wpOld) ? dataU.professionalData.wpOld : ''
    this.monthlySalary = (dataU.professionalData && dataU.professionalData.monthlySalary) ? dataU.professionalData.monthlySalary : ''
    this.monthlyRevenue = (dataU.professionalData && dataU.professionalData.monthlyRevenue) ? dataU.professionalData.monthlyRevenue : ''
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      this.$refs.professionalDataForm.validate().then(success => {
        if (success) {
          const proData = this
          const bodyPro = {}
          bodyPro.studyLevel = proData.studyLevel
          bodyPro.profession = proData.profession
          bodyPro.ciiu = proData.ciiu
          bodyPro.mainActivity = proData.mainActivity
          bodyPro.job = proData.job
          if (bodyPro.job.compuestoID === '000004' || bodyPro.job.compuestoID === '000003') {
            bodyPro.wpStreetNumber = proData.wpStreetNumber
            bodyPro.workplace = proData.workplace
            bodyPro.wpRuc = proData.wpRuc
            bodyPro.wpStartdate = proData.wpStartdate
            bodyPro.wpPosition = proData.wpPosition
            bodyPro.wpPhone = proData.wpPhone
            bodyPro.wpEconomic = proData.wpEconomic
            bodyPro.wpEmail = proData.wpEmail
            bodyPro.wpAddress = proData.wpAddress
            bodyPro.wpStreet = proData.wpStreet
            bodyPro.wpStreet = proData.wpStreet
            bodyPro.wpBlock = proData.wpStreet
            bodyPro.wpLot = proData.wpStreet
            bodyPro.wpDepNumber = proData.wpDepNumber
            bodyPro.wpInside = proData.wpInside
            bodyPro.wpDistrict = proData.wpDistrict
            bodyPro.wpProvince = proData.wpProvince
            bodyPro.wpDepartment = proData.wpDepartment
            bodyPro.wpCountry = proData.wpCountry
            bodyPro.wpOld = proData.wpOld
          }

          if (bodyPro.job.compuestoID === '000003') {
            bodyPro.monthlySalary = proData.monthlySalary
          }

          if (bodyPro.job.compuestoID === '000004') {
            bodyPro.monthlyRevenue = proData.monthlyRevenue
          }
          useJwt.professionalData({
            ruc: this.ruc,
            email: this.email,
            proData: { ...bodyPro },
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'Professional-data', status: user.professionalData.completed },
                { root: true },
              )
              this.$router.replace('/registro/datos-personas-politicamente-expuestas')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.efidePartnerDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
